* {
	font-family: Open Sans, sans-serif;
}

body { background-color: $gr1}

.shift { 
	background-color: $white;
	margin-top: 360px;
}

.unshift { 
	margin-top: -330px;
}

.main-container {
	margin-top: 160px;
}

h2 {
	    color: $blue;
	    font-size: 28px;
	    line-height: 34px;
	    font-weight: 400;
}


h3 {
	    color: $blue;
	    font-size: 22px;
	    line-height: 28px;
	    font-weight: 400;
}




header{
	height: $menu-height;
	
	%menushared {
		font-size: $menu-items-font-size;
		color: $gr1;
		font-weight: 400;

		li {
			// list-style: none;
		}
	}



	#mobile-menu {
		@extend %menushared;
		position: fixed;
		top: 0px;
		left: 0px;
		width: 100vw;
		height: $menu-height;
		z-index: 999;
		// border-bottom: 1px solid $gr1;
		background-color: #ffffff;

		background-image: url(data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0A%3C%21--%20Generator%3A%20Adobe%20Illustrator%2022.0.0%2C%20SVG%20Export%20Plug-In%20.%20SVG%20Version%3A%206.00%20Build%200%29%20%20--%3E%0A%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20x%3D%220px%22%20y%3D%220px%22%0A%09%20viewBox%3D%220%200%20537.3%20127.3%22%20style%3D%22enable-background%3Anew%200%200%20537.3%20127.3%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Cstyle%20type%3D%22text/css%22%3E%0A%09.st0%7Bfill%3A%23CECD00%3B%7D%0A%09.st1%7Bfill%3A%235F227B%3B%7D%0A%3C/style%3E%0A%3Cpath%20class%3D%22st0%22%20d%3D%22M0%2C35.1c0%2C0%2C2.6-16.1%2C14.9-16.1S502.1%2C1%2C521.2%2C0c16.8%2C3.2%2C16%2C17.9%2C16%2C21.5c-1.9%2C11.5-13.7%2C93-14.6%2C95.6%0A%09c-0.9%2C2.7-5.6%2C10.2-11.5%2C10.2c-5.9%2C0-488.7-14.9-488.7-14.9S11.9%2C111.6%2C10%2C98.6S0%2C35.1%2C0%2C35.1z%22/%3E%0A%3Cpath%20class%3D%22st1%22%20d%3D%22M0%2C34.9c0%2C0%2C485.3%2C23.3%2C494.3%2C24.1c9%2C0.8%2C17.8%2C6.3%2C17.7%2C18.3c-0.2%2C12%2C0%2C50%2C0%2C50S27.1%2C112.4%2C21.5%2C112%0A%09c-5.6-0.5-9.6-6.3-10.6-10.1C9.9%2C98%2C0%2C34.9%2C0%2C34.9z%22/%3E%0A%3C/svg%3E%0A);
		background-repeat: no-repeat;

		// .top-bar {
		// 	img {
		// 		height: ($menu-height - 1);

		// 	}
		// }

		h1 {
			    color: #fff;
			    font-size: 36px;
			    line-height: 48px;
			    font-weight: 400;
			    margin-left: 50px;
			    font-style: italic;
			    position: absolute;
				margin-top: 96px;
		}


		.header-mobile {
		
			width: 110%;
			background-color: $gr1
		}

		#burger {
			border: $gr1 solid 3px;
			border-radius: 8px;
			width: 60px;
			height: 41px;
			float: right;
			margin-top: 100px;
			cursor: pointer;
			margin-right: 20px;

			.bar {
				border-bottom: $gr1 solid 3px;
				margin-top: 7px;
				width: 60%;
				margin-left: 20%;
				opacity: 1;
				transition: transform 0.5s ease, opacity 0.2s ease;
			}

			&.active {
				.bar:nth-child(1) {
					transform: translateY(10px) rotate(45deg);
				}

				.bar:nth-child(2) {
					transform: rotate(-45deg);
				}

				.bar:nth-child(3) {
					opacity: 0;
				}
			}
		}




		#mobileMenuItems {
			max-height: 0px;
			overflow: hidden;
			transition: max-height 0.5s ease, opacity 0s 0.5s linear;
			background-color: $white;
			z-index: 999;
			opacity: 0;
			padding: 0px 50px 15px 50px;
			margin-top: 50px;
			border: 1px solid $gr1;
			border-top: none;

			&.active {
				max-height: 300px;
				opacity: 1;
				transition: max-height 0.5s ease;
				background-color: $white;
				border: 1px solid $gr1;
			}

			ul {
				padding: 0px;

				li {
					margin-top: 10px;
					margin-bottom: 10px;
					padding: 5px 0px;

 			a {
				color: $purple;
				font-size: 22px;
				font-weight: 500;

		        &:hover, &.active {
		         	text-decoration: none;
		         	font-weight: 700;
		        }
	        }





				}
			}
		}
	}

	#menu {
		@extend %menushared;

		.logo {
			margin-top: -100px;
			height: 0px;
			width:100%;
			overflow:visible;
			display: block;
			
			img {
				width: 100%;
				z-index: 100;
				position:relative;
			}
		}

		.slogan {
			background-color: #ffffff;
			z-index: 1;
			position: relative;
			height: 170px;
			color: $purple;
			font-size: 16px;
			font-weight: 600;
			font-style: italic;
			padding-top: 40px;
			padding-left: 25px;
		}
	}

	.menu {
		width: 100%;
		background-color: $gr1;
		padding: 15px 30px 15px 20px;
		color: $purple;
		border-radius: 5px;
		margin-top: 35px;


		ul li {
			margin-left: -14px;
			line-height: 28px;

	         a {
				color: $purple;
				font-size: 16px;
				font-weight: 500;

		        &:hover, &.active {
		         	text-decoration: none;
		         	font-weight: 700;
		        }
	        }
		}
	}
}

section#mainContent {
	margin-top: -165px;

	.btn-purple {
			background-color: $purple;
			border-color: $purple;
			color: $white;
			// height: 60px;
			padding: 20px 40px 20px 40px ;
			font-weight: 600;
			width: 300px;

			&:hover { 
				background-color: $green;
				border-color: $green;
			}
		}

		.btn-green {
			background-color: $green;
			border-color: $green;
			color: $white;
			// height: 60px;
			padding: 20px 40px 20px 40px ;
			font-weight: 600;
			width: 300px;

			&:hover { 
				background-color: $purple;
				border-color: $purple;
			}
		}

		.fa-2x {
    		font-size: 1.5em;
    	}

	.bizrightmenu {
		background-image: url(/images/figuren.png);
		background-repeat: no-repeat;
		height: 0px;
		padding-top: 26%;
		position: absolute;
		background-size: cover;
		z-index: 1;
	}

	.bluepurple {

		background: url(data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0A%3C%21--%20Generator%3A%20Adobe%20Illustrator%2022.0.0%2C%20SVG%20Export%20Plug-In%20.%20SVG%20Version%3A%206.00%20Build%200%29%20%20--%3E%0A%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20x%3D%220px%22%20y%3D%220px%22%0A%09%20viewBox%3D%220%200%20537.3%20127.3%22%20style%3D%22enable-background%3Anew%200%200%20537.3%20127.3%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Cstyle%20type%3D%22text/css%22%3E%0A%09.st0%7Bfill%3A%23CECD00%3B%7D%0A%09.st1%7Bfill%3A%235F227B%3B%7D%0A%3C/style%3E%0A%3Cpath%20class%3D%22st0%22%20d%3D%22M0%2C35.1c0%2C0%2C2.6-16.1%2C14.9-16.1S502.1%2C1%2C521.2%2C0c16.8%2C3.2%2C16%2C17.9%2C16%2C21.5c-1.9%2C11.5-13.7%2C93-14.6%2C95.6%0A%09c-0.9%2C2.7-5.6%2C10.2-11.5%2C10.2c-5.9%2C0-488.7-14.9-488.7-14.9S11.9%2C111.6%2C10%2C98.6S0%2C35.1%2C0%2C35.1z%22/%3E%0A%3Cpath%20class%3D%22st1%22%20d%3D%22M0%2C34.9c0%2C0%2C485.3%2C23.3%2C494.3%2C24.1c9%2C0.8%2C17.8%2C6.3%2C17.7%2C18.3c-0.2%2C12%2C0%2C50%2C0%2C50S27.1%2C112.4%2C21.5%2C112%0A%09c-5.6-0.5-9.6-6.3-10.6-10.1C9.9%2C98%2C0%2C34.9%2C0%2C34.9z%22/%3E%0A%3C/svg%3E%0A);


		
		background-repeat: no-repeat;
		background-size: 95%;
		position: absolute;
		height: 195px;
		width: 101.5%;
		margin-top: 115px;
		z-index: 2;
		opacity: 0.98;

		h1 {
			color: $white;
			font-size: 36px;
			line-height: 48px;
			font-weight: 400;
			margin-left: 50px;
			margin-top: 80px;
			font-style: italic;
		}
	}

	.realContent {
		background-color: $white;
		margin-top: 170px;
		padding-top: 140px;
		padding-bottom:60px;
		padding-left: 50px;
		padding-right: 50px;

		h2 {
			    color: $blue;
			    font-size: 28px;
			    line-height: 34px;
			    font-weight: 400;
			    margin-bottom: 0px;
		}


		h3 {
			    color: $blue;
			    font-size: 22px;
			    line-height: 28px;
			    font-weight: 400;
			    margin-bottom: 0px;
		}

		ul {

			    margin-left: -20px;
			li {

				   font-size: 16px;
				   line-height: 24px;
				   margin: 0 0 10px;
			}			
		}

	}
}

footer {
	.row {
		padding: 60px;
		color : $purple
	}

	.footerblock {
		min-height: 200px;
		font-size: 14px;

		img { 
			width: 30%;
		}

		strong {
			font-size: 20px;

		}
	}

	.slogan {
		color : $purple;
		font-weight: 500;
		font-style: italic;
		font-size: 12px;
		padding-left: 25%;
	}
}