// xs
@media (max-width: 575px) {
	
}

// sm
@media (min-width: 576px) and (max-width: 767px) {
	
}

// md
@media (min-width: 768px) and (max-width: 991px) {
	
}

// l
@media (min-width: 992px) and (max-width: 1199px) {
	
	section#mainContent .bluepurple { width: 110.5% }
	header .slogan { padding-top: 60px !important;padding-left: 60px !important }
}

// xl
@media (min-width: 1200px) {

}



// <=sm
@media (max-width: 767px) {

	header #menu .logo {
			 height: initial !important;

			  img { width: 200px ; }
		}

}

// <=md
@media (max-width: 992px) {

	header #mobile-menu {
		height: 200px;
		background-size: cover ;
	}

	.unshift, .shift  { margin: 0px }

	 header #menu .slogan { 
	 	padding-top: 0px !important;
	 	padding-left:60px; 
	 	height: 60px;
	}

	section#mainContent {
		

		.realContent {
			margin-top: 0px;
		}
	

		.bluepurple { 

			display: none;

		    height: initial; 
		    margin-top: 27px;
		    z-index: 1000;
		    opacity: 0.98;
		    width: 113%;
		    position: relative;

		    h1 {
		    	margin-top : 40px; 
		    }
	    }

		


	}

	section#mainContent .bizrightmenu { display: none  }



	section#mainContent .realContent { padding-left: 00px; padding-right: 30px; padding-top: 0px }


}

// <=l
@media (max-width: 1199px) {



}