h1, h2, h3, .h1, .h2, .h3 {
	color: $black;
	font-weight: bold;
	color: $blue;
}

h1, .h1 {
	font-size: $h1size;
	line-height: $h1height;
}

h2, .h2 {
	font-size: $h2size;
	line-height: $h2height;
	font-weight: 500;
	margin-bottom:5px;
}

h3, .h3 {
	font-size: $h3size;
	line-height: $h3height;
	font-weight: 500;
	margin-bottom:5px;

}

p {
	font-size: 16px;
	line-height: 24px;
}